<template>
  <div>
    <h2>数据库上传进度</h2>
    <div class="sel">
      <span>状态查询:</span>
      <el-select v-model="value" placeholder="请选择" @change="selecte()">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="数据库名称" width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.dbName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="资源量" width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.resourceNum }}</span>
        </template>
      </el-table-column>
      <el-table-column label="上传时间" width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.uploadTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px" @click="ack(scope.row.failmessage)">{{
            scope.row.state
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >追加</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="失败原因"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div style=" text-align: center">{{ messagea }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { INSERT, QUERY, DELETE, UPDATE, QUERYED } from "@/services/dao.js";
export default {
  data() {
    return {
      options: [
        {
          value: "0",
          label: "正在上传",
        },
        {
          value: "1",
          label: "上传完成",
        },
        {
          value: "2",
          label: "上传失败",
        },
      ],
      value: "",
      visible: false,
      dialogVisible: false,
      messagea: "",
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
    };
  },
  created() {
    this.selecte();
  },
  methods: {
    handleEdit(index, row) {
      console.log(index, row);
    },
    async handleDelete(index, row) {
      console.log(index, row.id);
      let info = await DELETE(
        "post",
        "",
        "   delete_dbInformation(where: {id: {_eq: " +
          row.id +
          "}}) {   affected_rows  }"
      );
      if (info.data.delete_dbInformation.affected_rows > 0) {
        this.$message({
          message: "删除成功",
          type: "warning",
        });
        this.selecte();
      }
    },
    async selecte() {
      this.tableData = [];
      let info = await QUERYED(
        "post",
        "",
        'dbInformation( where: {state: {_like: "%' +
          this.value +
          '%"}},limit: ' +
          10 +
          ", offset: " +
          0 +
          ") { id dbName dbProvider chargeType resourceNum uploadTime state failmessage  }"
      );
      for (let i = 0; i < info.data.dbInformation.length; i++) {
        switch (info.data.dbInformation[i].state) {
          case "0":
            info.data.dbInformation[i].state = "正在上传 ";
            break;
          case "1":
            info.data.dbInformation[i].state = "上传成功 ";
            break;
          case "2":
            info.data.dbInformation[i].state = "上传失败 ";
            break;
        }
      }
      console.log(info.data.dbInformation);
      this.tableData = info.data.dbInformation;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    ack(failmessage) {
      this.dialogVisible = "true";

      this.messagea = failmessage;
    },
  },
};
</script>

<style scoped>
.sel {
  overflow: hidden;
  margin-right: 70px;
  float: right;
  font-size: 16px;
  margin-bottom: 10px;
}
.el-table {
  border: 1px solid #e5e5e5;
}
</style>
